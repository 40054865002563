<template>
  <div class="content-view-wrapper fullscreen">
    <div ref="sectionWrapper" class="content-view section-wrapper">
      <section class="container-404">
        <div class="wrapper-404">
          <div class="tip-image-404"></div>
          <p class="tip-text">非常抱歉! 您访问的页面似乎不见了！</p>
          <a href="/" class="back-home">
            返回首页
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-404"
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/error.scss";
</style>
